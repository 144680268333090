

/// NOTE: jQuery is actually imported via the rollup-inject plugin.
/// See rollup-core.config.mjs

// import * as jQuery from "jquery";

window.$ = window.jQuery = window.$j = jQuery;

// export * from "../wwwroot/scripts/jquery-migrate-3.3.0.js"
// export * from "../wwwroot/scripts/jquery-ui-1.13.2.js"

// import "jquery-ui/dist/jquery-ui.js"
import "jquery-ui/ui/version.js"
import "jquery-ui/ui/widget.js"
import "jquery-ui/ui/plugin.js"
import "jquery-ui/ui/unique-id.js"
import "jquery-ui/ui/data.js"
import "jquery-ui/ui/disable-selection.js"
import "jquery-ui/ui/safe-active-element.js"
import "jquery-ui/ui/scroll-parent.js"
import "jquery-ui/ui/position.js"
import "jquery-ui/ui/safe-blur.js"
import "jquery-ui/ui/tabbable.js"
import "jquery-ui/ui/focusable.js"
import "jquery-ui/ui/effect.js"
import "jquery-ui/ui/effects/effect-highlight.js"
import "jquery-ui/ui/effects/effect-shake.js"
import "jquery-ui/ui/widgets/accordion.js"
import "jquery-ui/ui/widgets/datepicker.js"
import "jquery-ui/ui/widgets/tooltip.js"
import "jquery-ui/ui/widgets/mouse.js"
import "jquery-ui/ui/widgets/resizable.js"
//import "jquery-ui/ui/widgets/sortable.js"
import "./legacy/jquery-ui.sortable.js" // use custom version due to https://bugs.jqueryui.com/ticket/15097/
import "jquery-ui/ui/widgets/draggable.js"
import "jquery-ui/ui/widgets/slider.js"
import "jquery-ui/ui/widgets/dialog.js"

// import "../wwwroot/scripts/jquery.ui.touch-punch.min.js"
import "./legacy/jquery.ui.touch-punch.js"

import "jquery-validation/dist/jquery.validate.js"

/// NOTE: jquery-glob changed into "globalize" and switched APIs, but there're
/// still calls to the legacy API.
import "./legacy/jquery.glob.min.js"

import _ from "lodash-es"
window._ = _;

import * as redom from "redom"
window.redom = redom;

import moment from "moment/moment.js"
window.moment = moment;

import popper from "popper.js/dist/esm/popper.js"
window.Popper = popper;

import "bootstrap/js/src/collapse.js"
import "bootstrap/js/src/tooltip.js"
import "bootstrap/js/src/button.js"
import "bootstrap/js/src/tab.js"
import "bootstrap/js/src/modal.js"
import "bootstrap/js/src/dropdown.js"

// signalR is built via webpack and patches `globalThis`/`window` already.
import * as signalR from "../wwwroot/js/signalr/dist/browser/signalr.js"

// TODO: Make sure globalization works as expected.
// TODO: Update CLDR & globalize versions from NPM
// See https://github.com/jefferypalmer/GSatTrackWeb/issues/1180
import "./legacy/cldr.js"
import "./legacy/cldr-event.js";
import "./legacy/cldr-supplemental.js"
import "./legacy/cldr-unresolved.js"
import "globalize/dist/globalize.js";
import "globalize/dist/globalize/number.js";


import * as utility from './utility.mjs';
window.utility = utility;

import "jquery.cookie"

import "jquery-contextmenu"


/// NOTE: Any jGrowl version higher than 1.2.5 will break the visuals of the
/// notification dialogs.
// import "jgrowl";	// NOTE: CSS
import "./legacy/jquery.jgrowl.mjs";	// NOTE: CSS

import Cookies from "js-cookie"
window.Cookies = Cookies;

import SimpleBar from "simplebar/dist/index.mjs"
window.SimpleBar = SimpleBar; // for nav-full.js

import * as JsSearch from 'js-search';
window.JsSearch = JsSearch;

import "datatables.net/js/jquery.dataTables.mjs";
import "datatables.net-bs4/js/dataTables.bootstrap4.mjs";

import "datatable-sorting-datetime-moment";

import "intro.js";
